import { BREAKPOINTS, Button, COLORS, ComponentM, ComponentMStyling, ComponentS, ComponentTextStyle, ComponentXS, ContentIcons, DropdownFilter, IconButton, InputLabel, PageWidth, Size, SystemIcon, SystemIcons, Textarea, TextField, ToastContext, TooltipWrapper } from "@laerdal/life-react-components";

import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import { countryList } from "../../model/constants/CountryList";
import { useContext, useRef, useState, DragEvent, ChangeEvent } from "react";
import { count } from "console";
import { FailToastOptions, SuccessToastOptions } from "../../model/constants/ToastConstants";
import CaseApi from "../../services/api/CaseApi";
import LaerdalCustomerCare from "../../commonComponents/LaerdalCustomerCare";
import posthog from 'posthog-js';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap:32px;
    display: flex;
    align-items: start;
    
`;

const CaseContent = styled.div`
    display: flex;
    max-width: 700px;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    
    align-self: center;
    align-items: start;
`;
const Title = styled.div`
    color: var(--Black, #1F1F1F);
    font-feature-settings: 'liga' off;
    /* S - Mobile/H3 */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
`;
const SubTitle = styled.div`
    color: ${COLORS.neutral_700}
    font-feature-settings: 'liga' off;
    /* M - Tablet/Paragraph */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
`;


const Breadcrumb = styled.div`
  padding:12px 0;
`;
const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const InputRow = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
    }
    flex-direction: column;

    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;
const InputCell = styled.div`
    align-self: stretch;
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-grow: 1;

    div {
        width: 100%;
    }

    textarea {
        width: calc(100% - 32px) !important;
        min-height:100px;
    }
`;

const CreateButton = styled(Button)`
    ${BREAKPOINTS.MEDIUM}{
        align-self: end;
    }
    align-self: stretch;
`;


const ChoseFileContainerText = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        display: flex;
    }
    display: none;
`
const ChoseFileContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    height: 152px;
    gap:21px;

    
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'  rx='4' ry='4' stroke='%23949494' stroke-width='2' stroke-dasharray='16%2c 16' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const AttachmentsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.neutral_20};
  padding: 24px 30px;

`;
const AttachmentDetails = styled.div`
  ${BREAKPOINTS.MEDIUM}{
    padding: 0px 10px;
  }
  padding: 5px 10px;

  background-color: ${COLORS.white};
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const AttachmentHeader = styled.div`
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
const Tooltip = styled(TooltipWrapper)`
    ${BREAKPOINTS.MEDIUM}{
        width: max-content;
    }
    width: unset;
`;




const CaseCreatePage = () => {
    const { addToast } = useContext(ToastContext);

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const [creatingcase, setCreatingcase] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [workEmail, setWorkEmail] = useState<string | undefined>(undefined);
    const [organization, setOrganization] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [country, setCountry] = useState<string | undefined>(undefined);

    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const allowedTypes = [
        'image/jpeg', 'image/png', 'image/gif', 'image/webp',
        'video/mp4', 'video/webm', 'video/ogg',
        'application/pdf', 'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
    ];

    const createCase = () => {
        if (creatingcase) return;

        if (firstName == undefined) setFirstName('');
        if (lastName == undefined) setLastName('');
        if (workEmail == undefined) setWorkEmail('');
        if (organization == undefined) setOrganization('');
        if (country == undefined) setCountry('');
        if (description == undefined) setDescription('');

        if (!!firstName
            && !!lastName
            && !!workEmail
            && isValidEmail(workEmail!)
            && !!organization
            && !!country
            && !!description
        ) {
            setCreatingcase(true);
            CaseApi.CreateCase({
                customerNumber: undefined,
                country: country,
                howCanWeHelp: 'Other',
                email: workEmail,
                subject: 'Support page - Request',
                description: `
                    First name: ${firstName}\n
                    Last name: ${lastName}\n
                    Organization: ${organization}\n
                    Country: ${country}\n
                    Description: ${description}\n
                `
            }, files).then(() => {

                posthog?.capture?.('CreateCaseModal Created', {
                    customerNumber: undefined,
                    country: country,
                    howCanWeHelp: 'Other',
                    email: workEmail,
                    subject: 'Support page - Request',
                    description: `
                        First name: ${firstName}\n
                        Last name: ${lastName}\n
                        Organization: ${organization}\n
                        Country: ${country}\n
                        Description: ${description}\n
                    `
                });
                addToast('Case created', SuccessToastOptions);
                setCreatingcase(false);

                setFirstName('');
                setLastName('');
                setWorkEmail('');
                setOrganization('');
                setDescription('');
                setCountry('');
                setFiles([])
            }).catch((ex) => {
                if (ex?.response?.data?.errors['']?.some((error: string) => error?.includes('Request body too large'))) {
                    posthog?.capture?.('CreateCaseModal documents too large', {
                        customerNumber: undefined,
                        country: country,
                        howCanWeHelp: 'Other',
                        email: workEmail,
                        subject: 'Support page - Request',
                        description: `
                            First name: ${firstName}\n
                            Last name: ${lastName}\n
                            Organization: ${organization}\n
                            Country: ${country}\n
                            Description: ${description}\n
                        `
                    });
                    addToast('The documents are too large! Maximum size of all documents must be under 30MB', FailToastOptions);
                }
                else {
                    posthog?.capture?.('CreateCaseModal Error', {
                        customerNumber: undefined,
                        country: country,
                        howCanWeHelp: 'Other',
                        email: workEmail,
                        subject: 'Support page - Request',
                        description: `
                            First name: ${firstName}\n
                            Last name: ${lastName}\n
                            Organization: ${organization}\n
                            Country: ${country}\n
                            Description: ${description}\n
                        `
                    });
                    addToast('There was a problem creating the case', FailToastOptions);
                }
                setCreatingcase(false);
            })
        }
    };

    const handleFiles = (newFiles: FileList | null) => {
        if (newFiles) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(newFiles)]);
            if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Clear the current file input
            }
        }
    }
    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const droppedFiles = e.dataTransfer.files;
        handleFiles(droppedFiles);
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };
    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        fileInputRef.current?.click();
    };

    const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleFiles(e.target.files);
    };

    const handleRemoveFile = (index: number) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <>
            <PageWidth useMaxWidth={true} maxWidth={1024} style={{ width: "calc(100% - 32px)" }}>
                <PageContainer>
                    <Breadcrumb>
                        <CrumbLink href='/'>
                            Home
                        </CrumbLink>
                        <Devider>/</Devider>
                        <CrumbText>
                            Create a new case
                        </CrumbText>
                    </Breadcrumb>
                    <CaseContent>
                        <Title>Create a case?</Title>
                        <SubTitle>Share some details with us and we will tailor the best solution for you. Your information will help us offer the most relevant help.</SubTitle>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="FirstName" text="First name" size={Size.Small} required={true} />
                                <TextField
                                    id="FirstName"
                                    data-testId="FirstName"
                                    value={firstName}
                                    validationMessage={firstName == undefined || firstName.length > 0 ? '' : "Required"}
                                    onChange={(x) => setFirstName(x)}
                                    placeholder="e.g. Annie"
                                    size={Size.Small} />
                            </InputCell>

                            <InputCell>
                                <InputLabel inputId="LastName" text="Last name" size={Size.Small} required={true} />
                                <TextField
                                    id="FirstNamLastName"
                                    data-testId="LastName"
                                    value={lastName}
                                    validationMessage={lastName == undefined || lastName.length > 0 ? '' : "Required"}
                                    onChange={(x) => setLastName(x)}
                                    placeholder="e.g. Laerdal"
                                    size={Size.Small} />
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="WorkEmail" text="Work email" size={Size.Small} required={true} />
                                <TextField
                                    id="WorkEmail"
                                    data-testId="WorkEmail"
                                    value={workEmail}
                                    validationMessage={workEmail == undefined ? ''
                                        : workEmail == '' ? "Required"
                                            : (isValidEmail(workEmail!) ? '' : 'Not an email')
                                    }
                                    onChange={(x) => setWorkEmail(x)}
                                    placeholder="e.g. annie.laerdal@laerdal.com"
                                    size={Size.Small} />
                            </InputCell>

                            <InputCell>
                                <InputLabel inputId="Organisation" text="Organisation" size={Size.Small} required={true} />
                                <TextField
                                    id="Organisation"
                                    data-testId="Organisation"
                                    value={organization}
                                    validationMessage={organization == undefined || organization.length > 0 ? '' : "Required"}
                                    onChange={(x) => setOrganization(x)}
                                    placeholder="e.g. Laerdal Medical"
                                    size={Size.Small} />
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="Country" text="COUNTRY" size={Size.Small} required={true} />
                                <DropdownFilter
                                    activeValidationMessage={country == undefined || country.length > 0 ? '' : "Required"}
                                    scrollable={true}
                                    id="Country"
                                    dataTestId='CountryId'
                                    list={countryList}
                                    value={country}
                                    placeholder="-- Select an option --"
                                    onSelect={(value) => setCountry(value)}
                                    messageOnNoResults="No results found"
                                    size={Size.Medium} />
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="Description" text="Description" size={Size.Small} required={true} />
                                <Textarea
                                    id="Description"
                                    size={Size.Medium}
                                    data-testId="Description"
                                    value={description}
                                    validationMessage={description == undefined || description.length > 0 ? '' : "Required"}
                                    onChange={(x) => setDescription(x)}
                                    placeholder="Please describe what you need assistance with"
                                />
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <ChoseFileContainer
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={handleClick}>
                                <ChoseFileContainerText>
                                    <ComponentM textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_500}>Drag files here to upload them, or</ComponentM>
                                </ChoseFileContainerText>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    multiple
                                    accept={allowedTypes.join(',')}
                                    style={{ display: 'none' }}
                                />

                                <Button icon={<SystemIcons.Upload />} onClick={handleButtonClick} variant="secondary">Chose File</Button>

                            </ChoseFileContainer>
                        </InputRow>


                        <InputRow>
                            {files.length > 0 && (
                                <AttachmentsContainer>
                                    <AttachmentHeader>
                                        <ComponentXS textStyle={ComponentTextStyle.Bold}>ATTACHMENTS </ComponentXS>
                                        <Tooltip label="These attachments can be seen and downloaded by Laerdal Support" position="top" delay="0">
                                            <SystemIcons.Information />
                                        </Tooltip>
                                    </AttachmentHeader>

                                    <>
                                        {files.map((file, index) => (
                                            <AttachmentDetails key={index}>
                                                <IconButton variant="secondary" action={() => handleRemoveFile(index)}>
                                                    <SystemIcons.Close color={COLORS.neutral_500} />
                                                </IconButton>

                                                <ComponentS color={COLORS.neutral_500} >{file.name}</ComponentS>
                                            </AttachmentDetails>
                                        ))}
                                    </>
                                </AttachmentsContainer>
                            )}
                        </InputRow>


                        <CreateButton
                            size={Size.Large}
                            loading={creatingcase}
                            onClick={createCase}>
                            Create a case
                        </CreateButton>
                    </CaseContent>
                </PageContainer>
            </PageWidth>
            <LaerdalCustomerCare />
        </>);
};

export default CaseCreatePage;