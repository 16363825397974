import { SiteFooter, ToastProvider } from '@laerdal/life-react-components';
import { Component, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, useLocation} from 'react-router';
import styled from 'styled-components';
import ArticlePage from './pages/articlePage/ArticlePage';
import ArticleSearchPage from './pages/articleSearchPage/ArticleSearchPage';
import LandingPage from './pages/landingPage/LandingPage';
import EmbededSearchPage from './pages/embededSearchPage/EmbededSearchPage';
import EmbededArticlePage from './pages/embededArticlePage/EmbededArticlePage';
import { Routes } from 'react-router-dom';
import Header from './commonComponents/Header';
import posthog from 'posthog-js';
import BabyNewbornPage from './pages/topics/BabyNewbornPage';
import KidJuniorPage from './pages/topics/KidJuniorPage';
import MaleManikinPage from './pages/topics/MaleManikinPage';
import FemaleManikinPage from './pages/topics/FemaleManikinPage';
import SoftwarePage from './pages/topics/SoftwarePage';
import MedicalDevicesPage from './pages/topics/MedicalDevicesPage';
import SimBabyMKIIPage from './pages/topics/BabyNewborn/SimBabyMKII';
import LittleBabyQcprPage from './pages/topics/BabyNewborn/LittleBabyQcprPage';
import NursingBabyPage from './pages/topics/BabyNewborn/NursingBabyPage';
import PrematureAnneBabyPage from './pages/topics/BabyNewborn/PrematureAnneBabyPage';
import ResusciBabyFirstAidPage from './pages/topics/BabyNewborn/ResusciBabyFirstAidPage';
import SimNewBTetherlessPage from './pages/topics/BabyNewborn/SimNewBTetherlessPage';
import AlsBabyPage from './pages/topics/BabyNewborn/AlsBabyPage';
import MiniBabyPage from './pages/topics/BabyNewborn/MiniBabyPage';
import ResusciBabyQcprPage from './pages/topics/BabyNewborn/ResusciBabyQcprPage';
import SimBabyMKIIAccessoriesPage from './pages/topics/BabyNewborn/SimBabyMKII/Accessories';
import SimBabyMKIIArmsPage from './pages/topics/BabyNewborn/SimBabyMKII/Arms';
import SimBabyMKIIHeadPage from './pages/topics/BabyNewborn/SimBabyMKII/Head';
import SimBabyMKIILegsPage from './pages/topics/BabyNewborn/SimBabyMKII/Legs';
import SimBabyMKIITorsoPage from './pages/topics/BabyNewborn/SimBabyMKII/Torso';
import SimBabyMKIIDocumentationPage from './pages/topics/BabyNewborn/SimBabyMKII/Documentation';
import AlsBabyLegsPage from './pages/topics/BabyNewborn/AlsBaby/Legs';
import LittleBabyQcprDocumentation from './pages/topics/BabyNewborn/LittleBabyQcpr/Documentation';
import LittleBabyHeadPage from './pages/topics/BabyNewborn/LittleBabyQcpr/Head';
import LittleBabyQcprTorso from './pages/topics/BabyNewborn/LittleBabyQcpr/Torso';
import MiniBabyDocumentationPage from './pages/topics/BabyNewborn/MiniBabyPage/Documentation';
import NursingBabyDocumentationPage from './pages/topics/BabyNewborn/NursingBaby/Documentation';
import NursingBabyHeadPage from './pages/topics/BabyNewborn/NursingBaby/Head';
import NursingBabyLegsPage from './pages/topics/BabyNewborn/NursingBaby/Legs';
import NursingBabyTorsoPage from './pages/topics/BabyNewborn/NursingBaby/Torso';
import PrematureAnneBabyTaskTrainerPage from './pages/topics/BabyNewborn/PrematureAnneBaby/TaskTrainer';
import PrematureAnneBabyDocumentationPage from './pages/topics/BabyNewborn/PrematureAnneBaby/Documentation';
import ResusciBabyQcprDocumentationPage from './pages/topics/BabyNewborn/ResusciBabyQcpr/Documentation';
import ResusciBabyQcprTorsoPage from './pages/topics/BabyNewborn/ResusciBabyQcpr/Torso';
import ResusciBabyQcprAccessoriesPage from './pages/topics/BabyNewborn/ResusciBabyQcpr/Accessories';
import ResusciAnneQcprPage from './pages/topics/FemaleManikin/ResusciAnneQcprPage';
import ResusciAnneQcprAccessoriesPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/Accessories';
import ResusciAnneQcprDocumentationPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/Documentation';
import ResusciAnneQcprHeadPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/Head';
import ResusciAnneQcprSoftwarePage from './pages/topics/FemaleManikin/ResusciAnneQcpr/Software';
import ResusciAnneQcprTorsoPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/Torso';
import ResusciAnneQcprUpgradeKitsPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/UpgradeKits';
import ResusciAnneQcprMobileApplicationPage from './pages/topics/FemaleManikin/ResusciAnneQcpr/MobileApplication';
import SimNewBTetherlessDocumentationPage from './pages/topics/BabyNewborn/SimNewBTetherless/Documentation';
import SimNewBTetherlessConsumablesPage from './pages/topics/BabyNewborn/SimNewBTetherless/Consumables';
import SimNewBTetherlessHeadPage from './pages/topics/BabyNewborn/SimNewBTetherless/Head';
import SimNewBTetherlessLegsPage from './pages/topics/BabyNewborn/SimNewBTetherless/Legs';
import SimNewBTetherlessTorsoPage from './pages/topics/BabyNewborn/SimNewBTetherless/Torso';
import LittleAnneQcprPage from './pages/topics/FemaleManikin/LittleAnneQcprPage';
import ResusciAnneSimulatorPage from './pages/topics/FemaleManikin/ResusciAnneSimulatorPage';
import LittleAnneStackablePage from './pages/topics/FemaleManikin/LittleAnneStackablePage';
import MiniAnnePlusPage from './pages/topics/FemaleManikin/MiniAnnePlusPage';
import NursingAnnePage from './pages/topics/FemaleManikin/NursingAnnePage';
import NursingAnneSimulatorPage from './pages/topics/FemaleManikin/NursingAnneSimulatorPage';
import ResusciAnneAdvancedSkillTrainerPage from './pages/topics/FemaleManikin/ResusciAnneAdvancedSkillTrainerPage';
import LittleAnneQcprDocumentationPage from './pages/topics/FemaleManikin/LittleAnneQcpr/Documentation';
import LittleAnneQcprAccessoriesPage from './pages/topics/FemaleManikin/LittleAnneQcpr/Accessories';
import LittleAnneQcprAirwayPage from './pages/topics/FemaleManikin/LittleAnneQcpr/Airway';
import LittleAnneQcprMobileApplicationPage from './pages/topics/FemaleManikin/LittleAnneQcpr/MobileApplication';
import LittleAnneQcprSkillGuidePage from './pages/topics/FemaleManikin/LittleAnneQcpr/SkillGuide';
import LittleAnneQcprTorsoPage from './pages/topics/FemaleManikin/LittleAnneQcpr/Torso';
import LittleAnneQcprUpgradeKitsPage from './pages/topics/FemaleManikin/LittleAnneQcpr/UpgradeKits';
import LittleAnneStackableDocumentationPage from './pages/topics/FemaleManikin/LittleAnneStackable/Documentation';
import LittleAnneStackableTorsoPage from './pages/topics/FemaleManikin/LittleAnneStackable/Torso';

import MiniAnnePage from './pages/topics/FemaleManikin/MiniAnnePage';
import MiniAnnePlusDocumentationPage from './pages/topics/FemaleManikin/MiniAnnePlus/Documentation';
import MiniAnnePlusConsumablesPage from './pages/topics/FemaleManikin/MiniAnnePlus/Consumables';
import MiniAnnePlusInflatableBodyCompletePage from './pages/topics/FemaleManikin/MiniAnnePlus/InflatableBodyComplete';
import NursingAnneDocumentationPage from './pages/topics/FemaleManikin/NursingAnne/Documentation';
import NursingAnneAccessoriesPage from './pages/topics/FemaleManikin/NursingAnne/Accessories';
import NursingAnneHeadPage from './pages/topics/FemaleManikin/NursingAnne/Head';
import NursingAnneTorsoPage from './pages/topics/FemaleManikin/NursingAnne/Torso';
import NursingAnnePelvisPage from './pages/topics/FemaleManikin/NursingAnne/Pelvis';
import MiniAnneDocumentationPage from './pages/topics/FemaleManikin/MiniAnne/Documentation';
import NursingAnneSimulatorHeadPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Head';
import NursingAnneSimulatorArmsPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Arms';
import NursingAnneSimulatorLegsPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Legs';
import NursingAnneSimulatorTorsoPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Torso';
import NursingAnneSimulatorPelvisPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Pelvis';
import NursingAnneSimulatorAccessoriesPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Accessories';
import NursingAnneSimulatorDocumentationPage from './pages/topics/FemaleManikin/NursingAnneSimulator/Documentation';
import ResusciAnneAdvancedSkillTrainerDocumentationPage from './pages/topics/FemaleManikin/ResusciAnneAdvancedSkillTrainer/Documentation';
import ResusciAnneSimulatorDocumentationPage from './pages/topics/FemaleManikin/ResusciAnneSimulator/Documentation';
import ResusciAnneSimulatorAccessoriesPage from './pages/topics/FemaleManikin/ResusciAnneSimulator/Accessories';
import ResusciAnneSimulatorHeadPage from './pages/topics/FemaleManikin/ResusciAnneSimulator/Head';
import ResusciAnneSimulatorSoftwarePage from './pages/topics/FemaleManikin/ResusciAnneSimulator/Software';
import ResusciAnneSimulatorTorsoPage from './pages/topics/FemaleManikin/ResusciAnneSimulator/Torso';
import LittleJuniorQcprPage from './pages/topics/KidJunior/LittleJuniorQcprPage';
import SimJuniorPage from './pages/topics/KidJunior/SimJuniorPage';
import ResusciJuniorQcprPage from './pages/topics/KidJunior/ResusciJuniorQcprPage';
import NursingKidPage from './pages/topics/KidJunior/NursingKidPage';
import MegaCodeKidPage from './pages/topics/KidJunior/MegaCodeKidPage';
import KidJuniorDocumentationPage from './pages/topics/KidJunior/LittleJuniorQcpr/Documentation';
import MegaCodeKidDocumentationPage from './pages/topics/KidJunior/MegaCodeKid/Documentation';
import MegaCodeKidHeadPage from './pages/topics/KidJunior/MegaCodeKid/Head';
import NursingKidDocumentationPage from './pages/topics/KidJunior/NursingKid/Documentation';
import NursingKidHeadPage from './pages/topics/KidJunior/NursingKid/Head';
import NursingKidTorsoPage from './pages/topics/KidJunior/NursingKid/Torso';
import ResusciJuniorDocumentationPage from './pages/topics/KidJunior/ResusciJuniorQcpr/Documentation';
import ResusciJuniorAccessoriesPage from './pages/topics/KidJunior/ResusciJuniorQcpr/Accessories';
import ResusciJuniorHeadPage from './pages/topics/KidJunior/ResusciJuniorQcpr/Head';
import ResusciJuniorTorsoPage from './pages/topics/KidJunior/ResusciJuniorQcpr/Torso';
import SimJuniorDocumentationPage from './pages/topics/KidJunior/SimJunior/Documentation';
import SimJuniorAccessoriesPage from './pages/topics/KidJunior/SimJunior/Accessories';
import SimJuniorHeadPage from './pages/topics/KidJunior/SimJunior/Head';
import SimJuniorLegsPage from './pages/topics/KidJunior/SimJunior/Legs';
import SimJuniorSoftwarePage from './pages/topics/KidJunior/SimJunior/Software';
import SimJuniorTorsoPage from './pages/topics/KidJunior/SimJunior/Torso';
import CrashKellyPage from './pages/topics/MaleManikin/CrashKellyPage';
import MegaCodeKellyPage from './pages/topics/MaleManikin/MegaCodeKellyPage';
import SimMan3GEssentialPage from './pages/topics/MaleManikin/SimMan3GEssentialPage';
import SimMan3GEssentialBleedingPage from './pages/topics/MaleManikin/SimMan3GEssentialBleedingPage';
import SimMan3GPlusPage from './pages/topics/MaleManikin/SimMan3GPlusPage';
import SimMan3GTraumaPage from './pages/topics/MaleManikin/SimMan3GTraumaPage';
import SimManALSPage from './pages/topics/MaleManikin/SimManALSPage';
import SimManVascularPage from './pages/topics/MaleManikin/SimManVascularPage';
import CrashKellyTorsoPage from './pages/topics/MaleManikin/CrashKelly/Torso';
import CrashKellyHeadPage from './pages/topics/MaleManikin/CrashKelly/Head';
import MegaCodeKellyDocumentationPage from './pages/topics/MaleManikin/MegaCodeKelly/Documentation';
import MegaCodeKellyAccessoriesPage from './pages/topics/MaleManikin/MegaCodeKelly/Accessories';
import MegaCodeKellyArmsPage from './pages/topics/MaleManikin/MegaCodeKelly/Arms';
import MegaCodeKellyHeadPage from './pages/topics/MaleManikin/MegaCodeKelly/Head';
import MegaCodeKellyTorsoPage from './pages/topics/MaleManikin/MegaCodeKelly/Torso';
import SimMan3GEssentialDocumentationPage from './pages/topics/MaleManikin/SimMan3GEssential/Documentation';
import SimMan3GEssentialAccessoriesPage from './pages/topics/MaleManikin/SimMan3GEssential/Accessories';
import SimMan3GEssentialArmsPage from './pages/topics/MaleManikin/SimMan3GEssential/Arms';
import SimMan3GEssentialLegsPage from './pages/topics/MaleManikin/SimMan3GEssential/Legs';
import SimMan3GEssentialPelvisPage from './pages/topics/MaleManikin/SimMan3GEssential/Pelvis';
import SimManALSPageDocumentationPage from './pages/topics/MaleManikin/SimManALSPage/Documentation';
import SimManALSPageAccessoriesPage from './pages/topics/MaleManikin/SimManALSPage/Accessories';
import SimManALSPageShockLinkPage from './pages/topics/MaleManikin/SimManALSPage/ShockLink';
import SimManALSSimPadUnitPage from './pages/topics/MaleManikin/SimManALSPage/SimPadUnit';
import SimManALSSoftwarePage from './pages/topics/MaleManikin/SimManALSPage/Software';
import SimManALSTorsoPage from './pages/topics/MaleManikin/SimManALSPage/Torso';
import AedTrainerPage from './pages/topics/TrainingDevices/AedTrainer';
import AedTrainer1DocumentationPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer1/Documentation';
import AedTrainer1MobilePadsPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer1/Pads';
import AedTrainer1MobileApplicationPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer1/MobileApplication';
import AedTrainer2DocumentationPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer2/Documentation';
import AedTrainer2SoftwarePage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer2/Software';
import AedTrainer2Page from './pages/topics/TrainingDevices/AedTrainer/AedTrainerPage2';
import AedTrainer3Page from './pages/topics/TrainingDevices/AedTrainer/AedTrainerPage3';
import AedTrainer1Page from './pages/topics/TrainingDevices/AedTrainer/AedTrainerPage1';
import AedTrainer3MobilePadsPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer3/Pads';
import AedTrainer3SoftwarePage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer3/Software';
import AedTrainer3DocumentationPage from './pages/topics/TrainingDevices/AedTrainer/AedTrainer3/Documentation';
import LleapPage from './pages/topics/Software/LleapPage';
import LleapComputerPage from './pages/topics/Software/LleapHw/Computer';
import LleapSoftwarePage from './pages/topics/Software/LleapHw/Software';
import QcprAppPage from './pages/topics/Software/QcprAppPage';
import QcprAppMobileAppPage from './pages/topics/Software/QcprApp/MobileApp';
import RqiPagePage from './pages/topics/TrainingDevices/RqiPage';
import RqiOtherPage from './pages/topics/TrainingDevices/RQI/Other';
import RqiCartPage from './pages/topics/TrainingDevices/RQI/RqiCart';
import RqiSoftwarePage from './pages/topics/TrainingDevices/RQI/Software';
import ShockLinkPage from './pages/topics/TrainingDevices/ShockLinkPage';
import ShockLinkDocumentationPage from './pages/topics/TrainingDevices/ShockLink/Documentation';
import ShockLinkMobileApplicationPage from './pages/topics/TrainingDevices/ShockLink/MobileApplication';
import ShockLinkShockLinkPage from './pages/topics/TrainingDevices/ShockLink/ShockLink';
import SkillGuidePage from './pages/topics/TrainingDevices/SkillGuidePage';
import TeamReporterPage from './pages/topics/Software/TeamReporterPage';
import BaxStrapPage from './pages/topics/MedicalDevices/BaxStrapPage';
import CPRmeter2Page from './pages/topics/MedicalDevices/CPRmeter2Page';
import FaceShieldsPages from './pages/topics/MedicalDevices/FaceShieldsPages';
import HeartStartTrainerPage from './pages/topics/MedicalDevices/HeartStartTrainerPage';
import LaerdalCompactSuctionUnitPage from './pages/topics/MedicalDevices/LaerdalCompactSuctionUnitPage';
import LaerdalSuctionUnitPage from './pages/topics/MedicalDevices/LaerdalSuctionUnitPage';
import LaerdalSiliconeResuscitatorsPage from './pages/topics/MedicalDevices/LaerdalSiliconeResuscitatorsPage';
import NeoBeatPage from './pages/topics/MedicalDevices/NeoBeatPage';
import NeoNatalieKitPage from './pages/topics/MedicalDevices/NeoNatalieKitPage';
import PenguinSuctionPage from './pages/topics/MedicalDevices/PenguinSuctionPage';
import PocketMaskPage from './pages/topics/MedicalDevices/PocketMaskPage';
import SiliconeMasksPage from './pages/topics/MedicalDevices/SiliconeMasksPage';
import SpeedBlocksPage from './pages/topics/MedicalDevices/SpeedBlocksPage';
import StifneckCollarsPage from './pages/topics/MedicalDevices/StifneckCollarsPage';
import StifneckSelectPage from './pages/topics/MedicalDevices/StifneckSelectPage';
import TheBagPage from './pages/topics/MedicalDevices/TheBagPage';
import ThomasTubeHolderPage from './pages/topics/MedicalDevices/ThomasTubeHolderPage';
import VVacPage from './pages/topics/MedicalDevices/VVacPage';
import CPRmeter2CPRmeterUnitPage from './pages/topics/MedicalDevices/CPRmeter2/CPRmeterUnit';
import CPRmeter2DocumentationPage from './pages/topics/MedicalDevices/CPRmeter2/Documentation';
import CPRmeter2MobileApplicationPage from './pages/topics/MedicalDevices/CPRmeter2/MobileApplication';
import LaerdalCompactSuctionUnitAccessoriesLSRPage from './pages/topics/MedicalDevices/LaerdalCompactSuctionUnit/AccessoriesLSR';
import LaerdalCompactSuctionUnitCabinetCasePage from './pages/topics/MedicalDevices/LaerdalCompactSuctionUnit/CabinetCase';
import LaerdalCompactSuctionUnitCanisterPage from './pages/topics/MedicalDevices/LaerdalCompactSuctionUnit/Canister';
import LaerdalCompactSuctionUnitDocumentationPage from './pages/topics/MedicalDevices/LaerdalCompactSuctionUnit/Documentation';
import LaerdalSuctionUnitAccessoriesLSRPage from './pages/topics/MedicalDevices/LaerdalSuctionUnit/AccessoriesLSR';
import LaerdalSuctionUnitCabinetCasePage from './pages/topics/MedicalDevices/LaerdalSuctionUnit/CabinetCase';
import LaerdalSuctionUnitDocumentationPage from './pages/topics/MedicalDevices/LaerdalSuctionUnit/Documentation';
import CaseCreatePage from './pages/cases/CaseCreatePage';
import TrainingDevicesPage from './pages/topics/TrainingDevices';
import NursingBabyPelvisPage from './pages/topics/BabyNewborn/NursingBaby/Pelvis';
import PartFinderPage from './pages/partFinderPage/PartFinderSearchPage';
import PartFinderSearchPage from './pages/partFinderPage/PartFinderSearchPage';
import PartFinderItemPage from './pages/partFinderPage/PartFinderItemPage';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FooterContainer = styled.div`
  margin-top: auto;
`;

const TestIframe = styled.iframe`
  height: 95vh;
`;



const App = () => {
  const location = useLocation();

  window.addEventListener("message",
    (event) => {
      if(event.data == 'closingHelpU'){
        // @ts-ignore
        posthog?.capture('helpU closed',{
          $geoip_disable: true,
        });
      }
    },
    false
  );

  useEffect(() => { 
    posthog.capture('$pageview')
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
        <ContentContainer>
        {location.pathname.startsWith('/embeded') == false &&
          <Header/>
        }

        <Routes>
          <Route path='/' element={<LandingPage/>} />
          <Route path='/articles' element={<ArticleSearchPage/>} />
          <Route path='/embeded/search' element={<EmbededSearchPage/>} />
          <Route path='/embeded/articles/:articleNumber' element={<EmbededArticlePage/>} />

          <Route path='/partFinder' element={<PartFinderSearchPage/>} />
          <Route path='/partFinder/:id' element={<PartFinderItemPage/>} />

          <Route path='/articles/:articleNumber' element={<ArticlePage/>} />
          <Route path='/articles/Knowledge/:title' element={<ArticlePage/>} />
          <Route path='/embeded-articles-peligo/single' element={<TestIframe src='https://laerdalhelputest.blob.core.windows.net/peligo-single/en/index-en.html'></TestIframe>} />
          <Route path='/embeded-articles-peligo/step' element={<TestIframe src='https://laerdalhelputest.blob.core.windows.net/peligo-html/en/index-en.html'></TestIframe>} />
          
          <Route path='/case/create' element={<CaseCreatePage />} />

          <Route path='/baby-newborn-manikin' element={<BabyNewbornPage />} />
            <Route path='/baby-newborn-manikin/simbaby-mk-ii' element={<SimBabyMKIIPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/accessories' element={<SimBabyMKIIAccessoriesPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/arms' element={<SimBabyMKIIArmsPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/head' element={<SimBabyMKIIHeadPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/legs' element={<SimBabyMKIILegsPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/torso' element={<SimBabyMKIITorsoPage />} />
              <Route path='/baby-newborn-manikin/simbaby-mk-ii/documentation' element={<SimBabyMKIIDocumentationPage />} />
            <Route path='/baby-newborn-manikin/simnewb-tetherless' element={<SimNewBTetherlessPage />} />
              <Route path='/baby-newborn-manikin/simnewb-tetherless/documentation' element={<SimNewBTetherlessDocumentationPage />} />
              <Route path='/baby-newborn-manikin/simnewb-tetherless/consumables' element={<SimNewBTetherlessConsumablesPage />} />
              <Route path='/baby-newborn-manikin/simnewb-tetherless/head' element={<SimNewBTetherlessHeadPage />} />
              <Route path='/baby-newborn-manikin/simnewb-tetherless/legs' element={<SimNewBTetherlessLegsPage />} />
              <Route path='/baby-newborn-manikin/simnewb-tetherless/torso' element={<SimNewBTetherlessTorsoPage />} />
            <Route path='/baby-newborn-manikin/little-baby-qcpr' element={<LittleBabyQcprPage />} />
              <Route path='/baby-newborn-manikin/little-baby-qcpr/documentation' element={<LittleBabyQcprDocumentation />} />
              <Route path='/baby-newborn-manikin/little-baby-qcpr/head' element={<LittleBabyHeadPage />} />
              <Route path='/baby-newborn-manikin/little-baby-qcpr/torso' element={<LittleBabyQcprTorso />} />
            <Route path='/baby-newborn-manikin/resusci-baby-qcpr' element={< ResusciBabyQcprPage />} />
              <Route path='/baby-newborn-manikin/resusci-baby-qcpr/accessories' element={< ResusciBabyQcprAccessoriesPage />} />
              <Route path='/baby-newborn-manikin/resusci-baby-qcpr/documentation' element={< ResusciBabyQcprDocumentationPage />} />
              <Route path='/baby-newborn-manikin/resusci-baby-qcpr/torso' element={< ResusciBabyQcprTorsoPage />} />
            <Route path='/baby-newborn-manikin/premature-anne-baby' element={< PrematureAnneBabyPage />} />
              <Route path='/baby-newborn-manikin/premature-anne-baby/documentation' element={< PrematureAnneBabyDocumentationPage />} />
              <Route path='/baby-newborn-manikin/premature-anne-baby/task-trainer' element={< PrematureAnneBabyTaskTrainerPage />} />
            <Route path='/baby-newborn-manikin/nursing-baby' element={< NursingBabyPage/>} />
              <Route path='/baby-newborn-manikin/nursing-baby/documentation' element={< NursingBabyDocumentationPage/>} />
              <Route path='/baby-newborn-manikin/nursing-baby/head' element={< NursingBabyHeadPage/>} />
              <Route path='/baby-newborn-manikin/nursing-baby/legs' element={< NursingBabyLegsPage/>} />
              <Route path='/baby-newborn-manikin/nursing-baby/torso' element={< NursingBabyTorsoPage/>} />
              <Route path='/baby-newborn-manikin/nursing-baby/pelvis' element={< NursingBabyPelvisPage/>} />
              
            <Route path='/baby-newborn-manikin/als-baby' element={< AlsBabyPage />} />
              <Route path='/baby-newborn-manikin/als-baby/legs' element={<AlsBabyLegsPage />} />
            <Route path='/baby-newborn-manikin/mini-baby' element={< MiniBabyPage />} />
              <Route path='/baby-newborn-manikin/mini-baby/documentation' element={< MiniBabyDocumentationPage />} />
            <Route path='/baby-newborn-manikin/resusci-baby-firstaid' element={< ResusciBabyFirstAidPage />} />

          <Route path='/kid-junior-manikin' element={<KidJuniorPage />} />
            <Route path='/kid-junior-manikin/little-junior-qcpr' element={<LittleJuniorQcprPage />} />
              <Route path='/kid-junior-manikin/little-junior-qcpr/documentation' element={<KidJuniorDocumentationPage />} />
            <Route path='/kid-junior-manikin/megacode-kid' element={<MegaCodeKidPage />} />
              <Route path='/kid-junior-manikin/megacode-kid/documentation' element={<MegaCodeKidDocumentationPage />} />
              <Route path='/kid-junior-manikin/megacode-kid/head' element={<MegaCodeKidHeadPage />} />
            <Route path='/kid-junior-manikin/nursing-kid' element={<NursingKidPage />} />
              <Route path='/kid-junior-manikin/nursing-kid/documentation' element={<NursingKidDocumentationPage />} />
              <Route path='/kid-junior-manikin/nursing-kid/head' element={<NursingKidHeadPage />} />
              <Route path='/kid-junior-manikin/nursing-kid/torso' element={<NursingKidTorsoPage />} />
            <Route path='/kid-junior-manikin/resusci-junior-qcpr' element={<ResusciJuniorQcprPage />} />
              <Route path='/kid-junior-manikin/resusci-junior-qcpr/documentation' element={<ResusciJuniorDocumentationPage />} />
              <Route path='/kid-junior-manikin/resusci-junior-qcpr/accessories' element={<ResusciJuniorAccessoriesPage />} />
              <Route path='/kid-junior-manikin/resusci-junior-qcpr/head' element={<ResusciJuniorHeadPage />} />
              <Route path='/kid-junior-manikin/resusci-junior-qcpr/torso' element={<ResusciJuniorTorsoPage />} />
            <Route path='/kid-junior-manikin/sim-junior' element={<SimJuniorPage />} />
              <Route path='/kid-junior-manikin/sim-junior/documentation' element={<SimJuniorDocumentationPage />} />
              <Route path='/kid-junior-manikin/sim-junior/accessories' element={<SimJuniorAccessoriesPage />} />
              <Route path='/kid-junior-manikin/sim-junior/head' element={<SimJuniorHeadPage />} />
              <Route path='/kid-junior-manikin/sim-junior/legs' element={<SimJuniorLegsPage />} />
              <Route path='/kid-junior-manikin/sim-junior/software' element={<SimJuniorSoftwarePage />} />
              <Route path='/kid-junior-manikin/sim-junior/torso' element={<SimJuniorTorsoPage />} />
            
            <Route path='/male-manikin' element={<MaleManikinPage />} />
              <Route path='/male-manikin/crash-kelly' element={<CrashKellyPage />} />
                <Route path='/male-manikin/crash-kelly/torso' element={<CrashKellyTorsoPage />} />
                <Route path='/male-manikin/crash-kelly/head' element={<CrashKellyHeadPage />} />
              <Route path='/male-manikin/megacode-kelly' element={<MegaCodeKellyPage />} />
                <Route path='/male-manikin/megacode-kelly/documentation' element={<MegaCodeKellyDocumentationPage />} />
                <Route path='/male-manikin/megacode-kelly/accessories' element={<MegaCodeKellyAccessoriesPage />} />
                <Route path='/male-manikin/megacode-kelly/arms' element={<MegaCodeKellyArmsPage />} />
                <Route path='/male-manikin/megacode-kelly/head' element={<MegaCodeKellyHeadPage />} />
                <Route path='/male-manikin/megacode-kelly/torso' element={<MegaCodeKellyTorsoPage />} />
              <Route path='/male-manikin/simman-3g-essential-bleeding' element={<SimMan3GEssentialBleedingPage />} />
              <Route path='/male-manikin/simman-3g-essential' element={<SimMan3GEssentialPage />} />
                <Route path='/male-manikin/simman-3g-essential/documentation' element={<SimMan3GEssentialDocumentationPage />} />
                <Route path='/male-manikin/simman-3g-essential/accessories' element={<SimMan3GEssentialAccessoriesPage />} />
                <Route path='/male-manikin/simman-3g-essential/arms' element={<SimMan3GEssentialArmsPage />} />
                <Route path='/male-manikin/simman-3g-essential/legs' element={<SimMan3GEssentialLegsPage />} />
                <Route path='/male-manikin/simman-3g-essential/pelvis' element={<SimMan3GEssentialPelvisPage />} />
              <Route path='/male-manikin/simman-3g-plus' element={<SimMan3GPlusPage />} />
              {/* <Route path='/male-manikin/simman-3g-trauma' element={<SimMan3GTraumaPage />} /> */}
              <Route path='/male-manikin/simman-als' element={<SimManALSPage />} />
                <Route path='/male-manikin/simman-als/documentation' element={<SimManALSPageDocumentationPage />} />
                <Route path='/male-manikin/simman-als/accessories' element={<SimManALSPageAccessoriesPage />} />
                <Route path='/male-manikin/simman-als/shocklink' element={<SimManALSPageShockLinkPage />} />
                <Route path='/male-manikin/simman-als/simpad-unit' element={<SimManALSSimPadUnitPage />} />
                <Route path='/male-manikin/simman-als/software' element={<SimManALSSoftwarePage />} />
                <Route path='/male-manikin/simman-als/torso' element={<SimManALSTorsoPage />} />
              {/* <Route path='/male-manikin/simman-vascular' element={<SimManVascularPage />} /> */}
              
          <Route path='/female-manikin' element={<FemaleManikinPage />} />
            <Route path='/female-manikin/little-anne-qcpr' element={< LittleAnneQcprPage />} />
              <Route path='/female-manikin/little-anne-qcpr/documentation' element={< LittleAnneQcprDocumentationPage />} />
              <Route path='/female-manikin/little-anne-qcpr/accessories' element={< LittleAnneQcprAccessoriesPage />} />
              <Route path='/female-manikin/little-anne-qcpr/airway' element={< LittleAnneQcprAirwayPage />} />
              <Route path='/female-manikin/little-anne-qcpr/mobile-application' element={< LittleAnneQcprMobileApplicationPage />} />
              <Route path='/female-manikin/little-anne-qcpr/skill-guide' element={< LittleAnneQcprSkillGuidePage />} />
              <Route path='/female-manikin/little-anne-qcpr/torso' element={< LittleAnneQcprTorsoPage />} />
              <Route path='/female-manikin/little-anne-qcpr/upgrade-kits' element={< LittleAnneQcprUpgradeKitsPage />} />
            <Route path='/female-manikin/resusci-anne-qcpr' element={< ResusciAnneQcprPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/accessories' element={< ResusciAnneQcprAccessoriesPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/documentation' element={< ResusciAnneQcprDocumentationPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/head' element={< ResusciAnneQcprHeadPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/mobile-application' element={< ResusciAnneQcprMobileApplicationPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/software' element={< ResusciAnneQcprSoftwarePage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/torso' element={< ResusciAnneQcprTorsoPage />} />
              <Route path='/female-manikin/resusci-anne-qcpr/upgrade-kits' element={< ResusciAnneQcprUpgradeKitsPage />} />
            <Route path='/female-manikin/resusci-anne-simulator' element={< ResusciAnneSimulatorPage />} />
              <Route path='/female-manikin/resusci-anne-simulator/documentation' element={< ResusciAnneSimulatorDocumentationPage />} />
              <Route path='/female-manikin/resusci-anne-simulator/accessories' element={< ResusciAnneSimulatorAccessoriesPage />} />
              <Route path='/female-manikin/resusci-anne-simulator/head' element={< ResusciAnneSimulatorHeadPage />} />
              <Route path='/female-manikin/resusci-anne-simulator/software' element={< ResusciAnneSimulatorSoftwarePage />} />
              <Route path='/female-manikin/resusci-anne-simulator/torso' element={< ResusciAnneSimulatorTorsoPage />} />
            <Route path='/female-manikin/little-anne-stackable' element={< LittleAnneStackablePage />} />
              <Route path='/female-manikin/little-anne-stackable/documentation' element={< LittleAnneStackableDocumentationPage />} />
              <Route path='/female-manikin/little-anne-stackable/torso' element={< LittleAnneStackableTorsoPage />} />
            <Route path='/female-manikin/mini-anne' element={< MiniAnnePage />} />
              <Route path='/female-manikin/mini-anne/documentation' element={< MiniAnneDocumentationPage />} />
            <Route path='/female-manikin/mini-anne-plus' element={< MiniAnnePlusPage />} />
              <Route path='/female-manikin/mini-anne-plus/documentation' element={< MiniAnnePlusDocumentationPage />} />
              <Route path='/female-manikin/mini-anne-plus/consumables' element={< MiniAnnePlusConsumablesPage />} />
              <Route path='/female-manikin/mini-anne-plus/inflatable-body-complete' element={< MiniAnnePlusInflatableBodyCompletePage />} />
            <Route path='/female-manikin/nursing-anne' element={< NursingAnnePage />} />
              <Route path='/female-manikin/nursing-anne/documentation' element={< NursingAnneDocumentationPage />} />
              <Route path='/female-manikin/nursing-anne/accessories' element={< NursingAnneAccessoriesPage />} />
              <Route path='/female-manikin/nursing-anne/head' element={< NursingAnneHeadPage />} />
              <Route path='/female-manikin/nursing-anne/torso' element={< NursingAnneTorsoPage />} />
              <Route path='/female-manikin/nursing-anne/pelvis' element={< NursingAnnePelvisPage />} />
            <Route path='/female-manikin/nursing-anne-simulator' element={< NursingAnneSimulatorPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/documentation' element={< NursingAnneSimulatorDocumentationPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/accessories' element={< NursingAnneSimulatorAccessoriesPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/head' element={< NursingAnneSimulatorHeadPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/arms' element={< NursingAnneSimulatorArmsPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/legs' element={< NursingAnneSimulatorLegsPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/torso' element={< NursingAnneSimulatorTorsoPage />} />
              <Route path='/female-manikin/nursing-anne-simulator/pelvis' element={< NursingAnneSimulatorPelvisPage />} />
            <Route path='/female-manikin/resusci-anne-advanced-skilltrainer' element={< ResusciAnneAdvancedSkillTrainerPage />} />
              <Route path='/female-manikin/resusci-anne-advanced-skilltrainer/documentation' element={< ResusciAnneAdvancedSkillTrainerDocumentationPage />} />

          <Route path='/software' element={<SoftwarePage />} />
            <Route path='/software/teamreporter' element={<TeamReporterPage />} />
            <Route path='/software/qcpr-app' element={<QcprAppPage />} />
              <Route path='/software/qcpr-app/mobile-application' element={<QcprAppMobileAppPage />} />
            <Route path='/software/lleap' element={<LleapPage />} />
              <Route path='/software/lleap/computer' element={<LleapComputerPage />} />
              <Route path='/software/lleap/software' element={<LleapSoftwarePage />} />
          
            <Route path='/training-devices' element={<TrainingDevicesPage />} />
            <Route path='/training-devices/skill-guide' element={<SkillGuidePage />} />

            <Route path='/training-devices/shock-link' element={<ShockLinkPage />} />
              <Route path='/training-devices/shock-link/documentation' element={<ShockLinkDocumentationPage />} />
              <Route path='/training-devices/shock-link/mobile-application' element={<ShockLinkMobileApplicationPage />} />
              <Route path='/training-devices/shock-link/shocklink' element={<ShockLinkShockLinkPage />} />
            <Route path='/training-devices/rqi' element={<RqiPagePage />} />
              <Route path='/training-devices/rqi/other' element={<RqiOtherPage />} />
              <Route path='/training-devices/rqi/rqi-cart' element={<RqiCartPage />} />
              <Route path='/training-devices/rqi/software' element={<RqiSoftwarePage />} />
            <Route path='/training-devices/aed-trainer' element={<AedTrainerPage />} />
              <Route path='/training-devices/aed-trainer/aed-trainer' element={<AedTrainer1Page />} />
                <Route path='/training-devices/aed-trainer/aed-trainer/documentation' element={<AedTrainer1DocumentationPage />} />
                <Route path='/training-devices/aed-trainer/aed-trainer/pads' element={<AedTrainer1MobilePadsPage />} />
                <Route path='/training-devices/aed-trainer/aed-trainer/mobile-application' element={<AedTrainer1MobileApplicationPage />} />
              <Route path='/training-devices/aed-trainer/aed-trainer-2' element={<AedTrainer2Page />} />
                <Route path='/training-devices/aed-trainer/aed-trainer-2/documentation' element={<AedTrainer2DocumentationPage />} />
                <Route path='/training-devices/aed-trainer/aed-trainer-2/software' element={<AedTrainer2SoftwarePage />} />
              <Route path='/training-devices/aed-trainer/aed-trainer-3' element={<AedTrainer3Page />} />
                <Route path='/training-devices/aed-trainer/aed-trainer-3/documentation' element={<AedTrainer3DocumentationPage />} />
                <Route path='/training-devices/aed-trainer/aed-trainer-3/software' element={<AedTrainer3SoftwarePage />} />
                <Route path='/training-devices/aed-trainer/aed-trainer-3/pads' element={<AedTrainer3MobilePadsPage />} />

          <Route path='/medical-devices' element={<MedicalDevicesPage />} />
            <Route path='/medical-devices/baxstrap' element={<BaxStrapPage />} />
            <Route path='/medical-devices/cprmeter-2' element={< CPRmeter2Page/>} />
              <Route path='/medical-devices/cprmeter-2/cprmeter-unit' element={< CPRmeter2CPRmeterUnitPage/>} />
              <Route path='/medical-devices/cprmeter-2/documentation' element={< CPRmeter2DocumentationPage/>} />
              <Route path='/medical-devices/cprmeter-2/mobile-application' element={< CPRmeter2MobileApplicationPage/>} />
            <Route path='/medical-devices/face-shields' element={<FaceShieldsPages />} />
            <Route path='/medical-devices/heartstart-trainer' element={<HeartStartTrainerPage />} />
            <Route path='/medical-devices/laerdal-compact-suction-unit' element={<LaerdalCompactSuctionUnitPage />} />
              <Route path='/medical-devices/laerdal-compact-suction-unit/accessories-lsr' element={<LaerdalCompactSuctionUnitAccessoriesLSRPage />} />
              <Route path='/medical-devices/laerdal-compact-suction-unit/cabinet-case' element={<LaerdalCompactSuctionUnitCabinetCasePage />} />
              <Route path='/medical-devices/laerdal-compact-suction-unit/canister' element={<LaerdalCompactSuctionUnitCanisterPage />} />
              <Route path='/medical-devices/laerdal-compact-suction-unit/documentation' element={<LaerdalCompactSuctionUnitDocumentationPage />} />
            <Route path='/medical-devices/laerdal-silicone-resuscitators' element={<LaerdalSiliconeResuscitatorsPage />} />
            <Route path='/medical-devices/laerdal-suction-unit' element={<LaerdalSuctionUnitPage />} />
              <Route path='/medical-devices/laerdal-suction-unit/accessories-lsr' element={< LaerdalSuctionUnitAccessoriesLSRPage />} />
              <Route path='/medical-devices/laerdal-suction-unit/cabinet-case' element={< LaerdalSuctionUnitCabinetCasePage />} />
              <Route path='/medical-devices/laerdal-suction-unit/documentation' element={< LaerdalSuctionUnitDocumentationPage />} />
            <Route path='/medical-devices/neobeat' element={<NeoBeatPage />} />
            <Route path='/medical-devices/neonatalie-kit' element={<NeoNatalieKitPage />} />
            <Route path='/medical-devices/penguin-suction' element={<PenguinSuctionPage />} />
            <Route path='/medical-devices/pocket-mask' element={<PocketMaskPage />} />
            <Route path='/medical-devices/silicone-masks' element={<SiliconeMasksPage />} />
            <Route path='/medical-devices/speedblocks' element={<SpeedBlocksPage />} />
            <Route path='/medical-devices/stifneck-collars' element={<StifneckCollarsPage />} />
            <Route path='/medical-devices/stifneck-select' element={<StifneckSelectPage />} />
            <Route path='/medical-devices/the-bag' element={<TheBagPage />} />
            <Route path='/medical-devices/thomas-tubeholder' element={<ThomasTubeHolderPage />} />
            <Route path='/medical-devices/v-vac' element={<VVacPage />} />



        </Routes>


        {location.pathname.startsWith('/embeded') == false &&
          <FooterContainer>
            <SiteFooter 
              id="helpu-footer" 
              toTopText='TOP'
              sitename='Support'
              copyrightText={`Copyright © ${new Date().getFullYear()} Laerdal Medical. All Rights Reserved.`}
              bottomLinks={[
                  {
                    label: 'Terms of Use',
                    to: 'https://laerdal.com/support/customer-service/terms-of-use/',
                  },
                  {
                    label: 'Privacy Statement',
                    to: 'https://laerdal.com/support/customer-service/privacy-statement/',
                  },
                  {
                    label: 'Accessibility statement',
                    to: 'https://laerdal.com/support/customer-service/accessibility/',
                  },
                  {
                    label: 'Sustainability',
                    to: 'https://laerdal.com/about-us/sustainability/',
                  }
                ]}
              />
          </FooterContainer>
        }
        </ContentContainer>
    </>
  );
}

export default App;